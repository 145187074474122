import api from 'core/api/api';
import { handleError } from 'core/helpers/errorHandler';
import {
    RunsheetCreateDto,
    RunsheetReadDto,
    SearchResultDocuments,
    ExtendedRunsheetSave,
    ImageDto
} from 'types/dto/runsheetDto';

export const getInteractiveRunsheet = async (
    runsheetData: RunsheetCreateDto
): Promise<RunsheetReadDto> => {
    try {
        const response = await api.post<RunsheetCreateDto, RunsheetReadDto>(
            `api/runSheet/interactive`,
            runsheetData
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const getPdfRunsheet = async (
    runsheetData: RunsheetCreateDto
): Promise<{ buffer: ArrayBuffer; type: string }> => {
    try {
        const response = await api.postPdfFile<ArrayBuffer, RunsheetCreateDto>(
            `api/runSheet/pdf`,
            runsheetData
        );
        const contentType = response.headers['content-type'];
        return { buffer: response.data, type: contentType };
    } catch (e) {
        handleError(e);
    }
};

export const saveRunsheet = async (
    runsheetData: ExtendedRunsheetSave
): Promise<{
    isSuccess: boolean;
    message: string;
    searchResultDocuments: SearchResultDocuments[];
}> => {
    try {
        const response = await api.post<
            ExtendedRunsheetSave,
            {
                isSuccess: boolean;
                message: string;
                searchResultDocuments: SearchResultDocuments[];
            }
        >(`api/runSheet/save`, runsheetData);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const getImage = async (
    runsheetData: ImageDto
): Promise<{ buffer: ArrayBuffer; type: string }> => {
    try {
        const response = await api.postPdfFile<ArrayBuffer, RunsheetCreateDto>(
            `api/runSheet/image`,
            runsheetData,
            { showModalLoadingPopout: false }
        );
        const contentType = response.headers['content-type'];
        return { buffer: response.data, type: contentType };
    } catch (e) {
        let parsedJson = null;
        try {
            parsedJson = JSON.parse(new TextDecoder().decode(e.response.data as ArrayBuffer));
        } catch (e) {
            parsedJson = {};
        }
        if (parsedJson?.Message) {
            throw new Error(parsedJson?.Message);
        } else handleError(e);
    }
};
