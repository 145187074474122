import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { notificationBanner } from 'types/dataModels';
interface NotificationBannerState {
    notifications: notificationBanner[];
}
const getCurrentFormattedDate = () => new Date().toISOString().replace(/\.\d{3}Z$/, "");
const initialState: NotificationBannerState = {
    notifications: []
}
const notificationBannerSlice = createSlice({
    name: 'notificationBanner',
    initialState,
    reducers: {
        /**
         * Set Notifications to store
         * @param state Slice state
         * @param action Payload with the current Date
         */
        setNotifications(state, action: PayloadAction<notificationBanner[]>) {
            state.notifications = action.payload;
        }
    }
});
export const { setNotifications } = notificationBannerSlice.actions;
export default notificationBannerSlice.reducer;
/**
 * Fetch notifications from BE
 * @returns {AppThunk}
 */
export const fetchNotifications =
    (): AppThunk =>
        async (dispatch) => {
            try {
                const dateTimeString = encodeURIComponent(getCurrentFormattedDate());
                const response = await api.NotificationBanner.getNotifications(dateTimeString);
                dispatch(setNotifications(response));
            } catch (err) {
                dispatch(setNotifications([]));
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Get notifications : ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        };