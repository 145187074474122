import React, { useEffect, useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, InputAdornment, TextField } from '@mui/material';
import colors from 'core/constants/colors';
import { InputLabelPositions } from 'core/constants/common';
import { Icon, IconPaths } from 'components/styled/Icon';
import TooltipMain from 'components/styled/Tooltip';
import IconButton from 'components/styled/IconButton';
import { styled } from '@mui/material/styles';

export interface InputMainProps {
    id?: string;
    /**
     * Title for input component
     */
    label?: string;
    /**
     * Label position
     * @default InputLabelPositions.top
     */
    labelPosition?: InputLabelPositions;
    /**
     * Provide extra styling to label text
     */
    errorLabelStyle?: boolean;
    /**
     * Placeholder inside input
     */
    placeHolder?: string;
    /**
     * If true indicates an error in field
     */
    error?: boolean;
    /**
     * If true became disabled
     */
    disabled?: boolean;
    /**
     * If true show helper text under input, used when error is true
     */
    helperText?: string;
    /**
     * Show icon when error is true
     */
    withIcon?: boolean;
    /**
     * Handler for onChange event
     * @param event onChange event
     */
    width?: number;
    onChange?: (inputValue: string) => void;
    value?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    icon?: JSX.Element;
    onMouseUp?: (event: React.MouseEvent<HTMLInputElement>) => void;
    inputRef?: React.Ref<HTMLInputElement>;
    /**
     * Show remove icon
     */
    withRemoveIcon?: boolean;
    removeHandler?: (event: React.MouseEvent<HTMLInputElement>) => void;
    /**
     * Show expand icon
     */
    withExpandIcon?: boolean;
    expandHandler?: () => void;
    onBlur?: (inputValue: string) => void;
    onFocus?: (event: React.FocusEvent) => void;
    onPaste?: (event: React.ClipboardEvent) => void;
    isOnlyInteger?: boolean;
    /**
     * Input field max length validation limit
     */
    maxLength?: number;
    tempDisallowEnter?: boolean;
    dynamic?: boolean;
    multiline?: boolean;
    height?: string;
    padding?: string;
    autoCapitalize?: string;
    autoComplete?: string;
    autoCorrect?: string;
    name?: string;
    /**
     * For Numbering exceptions/requirments, added text align center property.
     * At all other places input will have default behavior
     */
    isTextCenterAligned?: boolean;
}

export const StyledInput = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'isTextCenterAligned'
})<Partial<InputMainProps>>(
    ({ width = '100%', height = '32px', padding = '8px', isTextCenterAligned = false }) => ({
        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '6px 4px'
        },
        '& .MuiInputBase-input': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: colors.main.primaryLight,
            fontFamily: 'Roboto, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            borderRadius: '0',
            height: height,
            width: width,
            padding: padding,
            caretColor: colors.main.accentHighlight,
            '& fieldset': {
                borderColor: colors.main.secondaryLight
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.main.accentHighlight
            },
            '&.Mui-error fieldset': {
                borderColor: colors.additional.error_fail
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: isTextCenterAligned ? '0px' : '8px',
            textAlign: isTextCenterAligned ? 'center' : 'left'
        },
        '& .MuiFormHelperText-root': {
            marginLeft: 0,
            marginTop: '2px'
        },
        '&.MuiFormControl-root': {
            width: '100%'
        }
    })
);

const InputLabel = styled('span')({
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 'max-content'
});

const ErrorInputLabel = styled('span')({
    fontFamily: 'Roboto, sans-serif',
    color: 'red',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 'max-content'
});
/**
 * Universal input styled component
 * @param {Function} onBlur onBlur handler
 * @param {Function} onFocus onFocus handler
 * @param {Function} onKeyDown onKeyDown handler
 * @param {string} label Label value
 * @param {InputLabelPositions} labelPosition Label position
 * @param {string} placeHolder Placeholder
 * @param {boolean} error Is have errors
 * @param {boolean} disabled Is input disabled
 * @param {string} helperText Helper text in bottom
 * @param {Function} onChange OnChange handler
 * @param {string} value Input value
 * @param {number} width Width
 * @param {boolean} withIcon Use with icon
 * @param {JSX.Element} icon Icon value
 * @param {string} id Id
 * @param {boolean} withRemoveIcon Render with remove icon
 * @param {Function} removeHandler Remove handler
 * @param {boolean} withExpandHandler
 * @param {Function} expandHandler Expand handler
 * @param {React.Ref<HTMLInputElement>} inputRef Input reference
 * @param {boolean} isOnlyInteger Input only for numbers
 * @param {boolean} autoCapitalize
 * @param {boolean} autoComplete
 * @param {boolean} autoCorrect
 * @param {boolean} isTextCenterAligned
 * @component
 * @returns {JSX.Element}
 */
const InputMain = ({
    onBlur,
    onFocus,
    onKeyDown,
    onPaste,
    label,
    errorLabelStyle,
    labelPosition = InputLabelPositions.top,
    placeHolder,
    error,
    disabled,
    helperText,
    withIcon,
    onChange,
    value = '',
    width,
    icon,
    id,
    withRemoveIcon = false,
    removeHandler,
    withExpandIcon = false,
    expandHandler,
    inputRef,
    isOnlyInteger = false,
    maxLength,
    tempDisallowEnter = false,
    dynamic = false,
    multiline = false,
    height,
    padding,
    autoCapitalize,
    autoComplete,
    autoCorrect,
    isTextCenterAligned = false
}: InputMainProps) => {
    const [inputValue, setInputValue] = useState<string>(value ? value : '');
    useEffect(() => {
        setInputValue(value);
    }, [value]);
    let resultIcon = icon;
    if (error && withIcon) {
        resultIcon = (
            <InputAdornment position="end">
                <WarningIcon color={'error'} />
            </InputAdornment>
        );
    }
    if (withRemoveIcon) {
        resultIcon = (
            <TooltipMain title={'Remove'}>
                <Box>
                    <IconButton
                        withoutBackground={true}
                        disableRipple
                        style={{ marginLeft: '8px' }}
                        onClick={removeHandler}
                        icon={
                            <InputAdornment
                                position="end"
                                style={{ width: '100%', height: '100%', margin: 0 }}>
                                <Icon
                                    fill={colors.main.primaryDark}
                                    path={IconPaths.close}></Icon>
                            </InputAdornment>
                        }
                        disabled={disabled}
                    />
                </Box>
            </TooltipMain>
        );
    }
    if (withExpandIcon) {
        resultIcon = (
            <TooltipMain title={'Expand'}>
                <Box>
                    <IconButton
                        withoutBackground={true}
                        disableRipple
                        onClick={expandHandler}
                        icon={
                            <InputAdornment
                                position="end"
                                style={{ width: '100%', height: '100%', margin: '0px' }}>
                                <Icon
                                    fill={colors.neutral.neutralDark}
                                    path={IconPaths.expand2}
                                    height={20}
                                    width={20}
                                    viewBox="0 0 24 24"></Icon>
                            </InputAdornment>
                        }
                        disabled={disabled}
                    />
                </Box>
            </TooltipMain>
        );
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: labelPosition === InputLabelPositions.left ? 'row' : 'column',
                gap: '6px',
                width: '100%'
            }}>
            {label && errorLabelStyle ? (
                <ErrorInputLabel>{label}</ErrorInputLabel>
            ) : (
                <InputLabel>{label}</InputLabel>
            )}
            <StyledInput
                id={id}
                inputRef={inputRef}
                width={width}
                onKeyDown={(e) => {
                    const event = e as React.KeyboardEvent<HTMLInputElement>;
                    if (!tempDisallowEnter) {
                        if (event.key === 'Enter') {
                            onChange(inputValue);
                        }
                    }
                    if (onKeyDown) onKeyDown(event);
                }}
                value={inputValue}
                onChange={(e) => {
                    const event = e as React.ChangeEvent<HTMLInputElement>;
                    if (isOnlyInteger) {
                        const convertedStringToNumber = Number(event.target.value);
                        if (!isNaN(convertedStringToNumber))
                            setInputValue(convertedStringToNumber.toString());
                    } else setInputValue(event.target.value);
                    if (onChange && dynamic) return onChange(event.target.value);
                }}
                onBlur={() => {
                    if (onBlur) {
                        onBlur(inputValue);
                    }
                    if (inputValue !== value) {
                        onChange(inputValue);
                    }
                }}
                onFocus={onFocus}
                onPaste={onPaste}
                placeholder={placeHolder}
                error={error}
                disabled={disabled}
                helperText={error && helperText}
                InputProps={{
                    endAdornment: resultIcon
                }}
                inputProps={{
                    maxLength: maxLength
                }}
                multiline={multiline}
                height={height}
                padding={padding}
                isTextCenterAligned={isTextCenterAligned}
                autoCapitalize={autoCapitalize ?? 'off'}
                autoComplete={autoComplete ?? 'off'}
                autoCorrect={autoCorrect ?? 'off'}
            />
        </Box>
    );
};

export default InputMain;
