import api from '../api';
import { handleError } from 'core/helpers/errorHandler';
import { notificationBanner } from 'types/dataModels';
/**
 * Get Notifications 
 * @function
 * @category API
 * @param {string} dateTimeString
 * @returns {notificationBanner[]}
 */
export const getNotifications = async (
    dateTimeString: string
): Promise<notificationBanner[]> => {
    try {
        const response = await api.get<notificationBanner[]>(
            `api/configManager/globalMessage/${dateTimeString}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};