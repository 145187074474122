import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DocumentAction,
    UploadKeyDocumentFormData,
    UploadKeyDocumentFormErrors
} from 'types/uploadKeyDocumentForm';
import { v4 as uuidv4 } from 'uuid';
import { ReferenceInputNames, AllowedPartyRolesMapping, FieldValueTypes } from 'core/constants/common';
import { KeyDocument, StringFieldDto } from 'types/dataModels';
import { AppThunk } from 'core/store/store';

type Mode = 'New' | 'Prefill';

interface UploadKeyDocumentFormState {
    isOpen: boolean;
    formData: UploadKeyDocumentFormData;
    fieldErrors: UploadKeyDocumentFormErrors;
    cansubmitForm: boolean;
    isDirty: boolean;
    mode: Mode;
    action: DocumentAction;
    document: KeyDocument;
}

export const initialState: UploadKeyDocumentFormState = {
    isOpen: false,
    formData: {
        category: { id: '', name: '' },
        subCategory: { id: '', name: '' },
        book: '',
        liber: '',
        page: '',
        isOcr: false,
        instrumentNumber: '',
        recordedDate: '',
        instrumentDate: '',
        datedDate: '',
        instrumentYear: null,
        parties: [
            {
                id: uuidv4(),
                fieldNameId: AllowedPartyRolesMapping[0].id,
                typedValue: '',
                docId: null,
                value: '',
                fieldType: FieldValueTypes.String
            },
            {
                id: uuidv4(),
                fieldNameId: AllowedPartyRolesMapping[1].id,
                typedValue: '',
                docId: null,
                value: '',
                fieldType: FieldValueTypes.String
            },
            {
                id: uuidv4(),
                fieldNameId: AllowedPartyRolesMapping[2].id,
                typedValue: '',
                docId: null,
                value: '',
                fieldType: FieldValueTypes.String
            }
        ],
        referenceInput: {
            [ReferenceInputNames.Book]: '',
            [ReferenceInputNames.Page]: '',
            [ReferenceInputNames.InstrumentNumber]: '',
            [ReferenceInputNames.InstrumentYear]: null
        },
        foundReferencedDocuments: [],
        notExistingReferencedDocuments: [],
        amount: null,
        transferTaxAmount: null,
        notes: '',
        codes: []
    },
    fieldErrors: {
        documentType: false,
        categoryId: false,
        subCategoryId: false,
        recordedDate: false
    },
    cansubmitForm: false,
    isDirty: false,
    mode: 'New',
    action: null,
    document: null
};

const uploadKeyDocumentFormSlice = createSlice({
    name: 'uploadKeyDocumentForm',
    initialState,
    reducers: {
        /**
         * Set isOpen
         * @param state Slice state
         * @param action Payload open value
         */
        setIsOpen(state, action: PayloadAction<boolean>) {
            state.isOpen = action.payload;
        },
        /**
         * Set the fields of the new key document form
         * @param state Slice state
         * @param action Payload with form data for new key document
         */
        setFormData(state, action: PayloadAction<UploadKeyDocumentFormData>) {
            state.formData = action.payload;
        },
        /**
         * Set the errors of the new key document form
         * @param state Slice state
         * @param action Payload with the new key document form errors
         */
        setFieldErrors(state, action: PayloadAction<UploadKeyDocumentFormErrors>) {
            state.fieldErrors = action.payload;
        },
        /**
         * Set canSubmitForm
         * @param state Slice state
         * @param action Payload can submit boolean
         */
        setCanSubmitForm(state, action: PayloadAction<boolean>) {
            state.cansubmitForm = action.payload;
        },
        /**
         * Set isDirty
         * @param state Slice state
         * @param action Payload is dirty boolean
         */
        setIsDirty(state, action: PayloadAction<boolean>) {
            state.isDirty = action.payload;
        },
        /**
         * Update the parties of the new key document
         * @param state Slice state
         * @param action Payload with the list of parties to set
         */
        setUploadKeyDocumentFormParties(
            state,
            action: PayloadAction<StringFieldDto[]>
        ) {
            state.formData.parties = action.payload;
        },
        /**
         * Set mode and prefill document
         * @param state Slice state
         * @param action Payload mode and document
         */
        setModeAndDocument(
            state,
            action: PayloadAction<{
                mode: Mode;
                action: DocumentAction;
                document: KeyDocument;
            }>
        ) {
            state.mode = action.payload.mode;
            state.document = action.payload.document;
            state.action = action.payload.action;
        }
    }
});

export const {
    setIsOpen,
    setFormData,
    setFieldErrors,
    setCanSubmitForm,
    setIsDirty,
    setUploadKeyDocumentFormParties,
    setModeAndDocument
} = uploadKeyDocumentFormSlice.actions;

/**
 * Create a new party field and chagne focus to it
 * @param {PulseDocumentPartyField[]} parties
 * @param {HTMLInputElement} ref
 * @returns {AppThunk}
 */
export const createUploadKeyDocumentFormParties =
    (parties: StringFieldDto[], ref?: HTMLInputElement): AppThunk =>
    async (dispatch) => {
        dispatch(setUploadKeyDocumentFormParties(parties));
        if (ref) setTimeout(() => ref.focus(), 100);
    };

export default uploadKeyDocumentFormSlice.reducer;
