import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { GetReqConfig, LegalDescriptionValue } from 'types/dataModels';
import { LegalDescriptionResultDto } from 'types/dto/legalDescription';
import { ValueDocLink } from 'types/dataModels';
import { WorkbenchSections } from 'core/constants/common';

/**
 * Get order legal description by orderId
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {GetReqConfig} config
 * @returns {LegalDescriptionValue[]}
 */
export const getExamOrderLegalDescription = async (
    orderId: string,
    config: GetReqConfig
): Promise<LegalDescriptionValue[]> => {
    try {
        const response = await api.get<LegalDescriptionValue[]>(
            `/api/examOrder/${orderId}/legalDescription`,
            {
                data: {
                    section: WorkbenchSections.legalDescription,
                    ...config
                }
            }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Save and Approve current legal value as legal value for order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} value - Value from editor
 * @param {boolean} isTagged - Value to save
 * @param {ValueDocLink[]} docLinks - Array of document links
 * @returns {LegalDescriptionResultDto}
 */
export const saveFinalOrderLegal = async (
    orderId: string,
    value: string,
    isTagged: boolean,
    docLinks: ValueDocLink[]
): Promise<LegalDescriptionResultDto> => {
    try {
        const response = await api.put<
            {
                examValue: string;
                isTagged: boolean;
                examValueDocLinks: ValueDocLink[];
            },
            LegalDescriptionResultDto
        >(`/api/examOrder/${orderId}/legalDescription/exam`, {
            examValue: value,
            isTagged: isTagged,
            examValueDocLinks: docLinks
        });
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Save current legal value as legal value for order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} value - Value from editor
 * @param {boolean} isTagged - Value to save
 * @param {ValueDocLink[]} docLinks - Array of document links
 * @returns {LegalDescriptionResultDto}
 */
export const onlySaveFinalOrderLegal = async (
    orderId: string,
    value: string,
    isTagged: boolean,
    docLinks: ValueDocLink[]
): Promise<LegalDescriptionResultDto> => {
    try {
        const response = await api.put<
            {
                examValue: string;
                isTagged: boolean;
                examValueDocLinks: ValueDocLink[];
            },
            LegalDescriptionResultDto
        >(`/api/examOrder/${orderId}/legalDescription/save`, {
            examValue: value,
            isTagged: isTagged,
            examValueDocLinks: docLinks
        });
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Apply current instrument as legal description for order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} legalId - Target legal id
 * @param {string} instrumentString - Instrument string value,
 * @param {ValueDocLink[]} linkArray - attached document array,
 * @returns {LegalDescriptionResultDto}
 */
export const applyInstrumentAsOrderLegal = async (
    orderId: string,
    legalId: string,
    instrumentString: string,
    linkArray?: ValueDocLink[]
): Promise<LegalDescriptionResultDto> => {
    try {
        const response = await api.put<
            {
                examValue: string;
                examValueDocLinks: ValueDocLink[];
            },
            LegalDescriptionResultDto
        >(`/api/examOrder/${orderId}/legalDescription/${legalId}/apply`, {
            examValue: instrumentString,
            examValueDocLinks: linkArray
        });
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
