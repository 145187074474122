import Header from 'components/common/AppHeader';
import InlineLoadingSpinner from 'components/common/InlineLoadingSpinner';
import LeftSidebar from 'components/common/LeftSidebar';
import LoadingSpinner from 'components/common/LoadingSpinner';
import {
    DASHBOARD,
    ORDERS,
    TEST_ORDER_FORM,
    TEST_ORDERS,
    WORKBENCH,
    WORKBENCH_FORCE_EDITABLE,
    CONFIG_MANAGER,
    USER_PROFILE,
    SANDBOX
} from 'core/constants/navigation';
import { fetchBusinessSegments } from 'core/features/businessSegments/businessSegmentsSlice';
import { fetchClient } from 'core/features/client/clientSlice';
import { fetchCounty } from 'core/features/county/countySlice';
import { fetchNotifications } from 'core/features/notificationBanner/notificationBannerSlice';
import { fetchDocumentTypes } from 'core/features/documentTypes/documentTypesSlice';
import { fetchInterestEstateTypes } from 'core/features/interestEstateType/interestEstateTypeSlice';
import { fetchProductTypes } from 'core/features/productTypes/productTypesSlice';
import { fetchProfileData } from 'core/features/profile/profileSlice';
import { fetchProjectStatuses } from 'core/features/projectStatus/projectStatusSlice';
import { fetchStates } from 'core/features/states/statesSlice';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import WorkbenchLayout from 'pages/Workbench/WorkbenchLayout';
import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { lazyRetry } from 'core/helpers/lazyRetry';
import { ErrorBoundary } from 'react-error-boundary';
import { fetchActions } from 'core/features/actions/actionsSlice';
import UserProfile from 'pages/UserProfile/UserProfile';
import CommonPopoutLayout from 'pages/Workbench/CommonPopoutLayout';
import DragAndDropCommonLayout from 'pages/Workbench/DragAndDropCommonLayout';
import FinalReview from 'pages/Workbench/FinalReview';
import { fetchInProgressOrders } from 'core/features/orders/ordersSlice';
import EmptyOrder from 'pages/Workbench/EmptyOrder';
import {
    createAndUpdateLoggedInUser,
    getUserRole
} from 'core/features/userProfile/userProfileSlice';
import { useMsal } from '@azure/msal-react';
import UnAuthorized from 'components/common/UnAuthorized';
import DownloadExternalFile from 'components/common/DownloadExternalFile';
import ProtectedRoute from 'components/common/RBAC/ProtectedRoute';
import { Roles } from 'core/constants/common';
import { EventMessage, EventType } from '@azure/msal-browser';
import ErrorDisplay from './ErrorDisplay';
import usePageTracking from './usePageTracking';

const Dashboard = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "Dashboard" */ 'pages/Dashboard'), 'Dashboard')
);

const Orders = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "Orders" */ 'pages/Orders'), 'Orders')
);
const TestOrderForm = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "TestOrderForm" */ 'pages/TestOrders/TestOrderForm'),
        'TestOrderForm'
    )
);
const Workbench = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "Workbench" */ 'pages/Workbench'), 'Workbench')
);
const PopoutLayout = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "PopoutLayout" */ 'pages/Workbench/PopoutLayout'),
        'PopoutLayout'
    )
);
const ConfigManager = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "ConfigManager" */ 'pages/ConfigManager'),
        'ConfigManager'
    )
);

const Sandbox = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: 'Sandbox' */ 'pages/Sandbox/'), 'Sandbox')
);

const PriorPolicyGeneralInformation = lazy(() =>
    lazyRetry(
        () =>
            import(
                /* webpackChunkName: "PriorPolicyGeneralInformation" */ 'pages/Workbench/FullScreenSidebar/PriorPolicyGeneralInformation'
            ),
        'PriorPolicyGeneralInformation'
    )
);

function AppLayout() {
    usePageTracking();
    const dispatch = useAppDispatch();
    const { instance } = useMsal();

    /**
     * initial data loading
     */
    useEffect(() => {
        async function init() {
            await dispatch(getUserRole(instance));
            dispatch(createAndUpdateLoggedInUser());
            dispatch(fetchClient());
            dispatch(fetchStates());
            dispatch(fetchCounty());
            dispatch(fetchNotifications());
            dispatch(fetchProductTypes());
            dispatch(fetchNotifications());
            dispatch(fetchProjectStatuses());
            dispatch(fetchDocumentTypes());
            dispatch(fetchBusinessSegments());
            dispatch(fetchActions());
            await dispatch(fetchProfileData());
            dispatch(fetchInterestEstateTypes());
            dispatch(fetchInProgressOrders());
        }
        init();
    }, []);

    useEffect(() => {
        const callbackId = instance.addEventCallback(async (message: EventMessage) => {
            if (message.eventType === EventType.LOGOUT_START) {
                localStorage.clear();
                sessionStorage.clear();
            }
        });
        return () => instance.removeEventCallback(callbackId);
    }, []);

    return (
        <div className="content">
            <Header />
            <LeftSidebar />
            <section className="mainContainer">
                <LoadingSpinner />
                <ErrorBoundary fallback={<ErrorDisplay />}>
                    <Outlet />
                </ErrorBoundary>
            </section>
        </div>
    );
}

/**
 * Authorized view wrapper for application
 * @component
 * @returns {JSX.Element}
 */
export const AuthorizedView = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<AppLayout />}>
                    <Route
                        path={DASHBOARD}
                        element={
                            <ProtectedRoute
                                allowedRoles={[Roles.PulseAdmin, Roles.PulseManager]}>
                                <Suspense fallback={<InlineLoadingSpinner />}>
                                    <Dashboard />
                                </Suspense>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ORDERS}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <Orders />
                            </Suspense>
                        }
                    />
                    <Route path={`${WORKBENCH}/empty`} element={<EmptyOrder />}>
                        <Route index element={<Workbench />} />
                    </Route>
                    <Route
                        path={`${WORKBENCH}/:orderId`}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <WorkbenchLayout />
                            </Suspense>
                        }>
                        <Route index element={<Workbench />} />
                    </Route>
                    <Route
                        path={`${WORKBENCH_FORCE_EDITABLE}/:orderId`}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <WorkbenchLayout forceEditable={true} />
                            </Suspense>
                        }>
                        <Route index element={<Workbench />} />
                    </Route>
                    <Route
                        path={`${TEST_ORDERS}/${TEST_ORDER_FORM}`}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <TestOrderForm />
                            </Suspense>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <Navigate to={`${ORDERS}`} replace />
                            </Suspense>
                        }
                    />
                    <Route
                        path={CONFIG_MANAGER}
                        element={
                            <ProtectedRoute allowedRoles={[Roles.PulseAdmin]}>
                                <Suspense fallback={<InlineLoadingSpinner />}>
                                    <ConfigManager />
                                </Suspense>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={USER_PROFILE}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <UserProfile />
                            </Suspense>
                        }
                    />
                    {process.env.NODE_ENV !== 'production' && (
                        <Route
                            path={SANDBOX}
                            element={
                                <Suspense fallback={<InlineLoadingSpinner />}>
                                    <Sandbox />
                                </Suspense>
                            }
                        />
                    )}
                </Route>
                <Route path={`/unauthorized`} element={<UnAuthorized />} />
                {/* Popout window routes */}
                <Route path={`/external-document/:path/`} element={<DownloadExternalFile />} />
                <Route
                    path={`/${WORKBENCH}/:orderId/documents`}
                    element={
                        <Suspense fallback={<InlineLoadingSpinner />}>
                            <PopoutLayout />
                        </Suspense>
                    }
                />
                <Route
                    path={`/${WORKBENCH_FORCE_EDITABLE}/:orderId/documents`}
                    element={
                        <Suspense fallback={<InlineLoadingSpinner />}>
                            <PopoutLayout forceEditable={true} />
                        </Suspense>
                    }
                />
                <Route
                    path={`/${WORKBENCH}/:orderId/final-review`}
                    element={
                        <Suspense fallback={<InlineLoadingSpinner />}>
                            <CommonPopoutLayout name="final-review-popout-window">
                                <FinalReview isPopout={true} />
                            </CommonPopoutLayout>
                        </Suspense>
                    }
                />
                <Route
                    path={`/${WORKBENCH_FORCE_EDITABLE}/:orderId/final-review`}
                    element={
                        <Suspense fallback={<InlineLoadingSpinner />}>
                            <CommonPopoutLayout
                                forceEditable={true}
                                name="final-review-popout-window">
                                <FinalReview isPopout={true} />
                            </CommonPopoutLayout>
                        </Suspense>
                    }
                />
                <Route
                    path={`/${WORKBENCH}/:orderId/:documentId/starters-and-priors`}
                    element={
                        <Suspense fallback={<InlineLoadingSpinner />}>
                            <DragAndDropCommonLayout>
                                <PriorPolicyGeneralInformation />
                            </DragAndDropCommonLayout>
                        </Suspense>
                    }
                />
                <Route
                    path={`/${WORKBENCH_FORCE_EDITABLE}/:orderId/:documentId/starters-and-priors`}
                    element={
                        <Suspense fallback={<InlineLoadingSpinner />}>
                            <DragAndDropCommonLayout forceEditable={true}>
                                <PriorPolicyGeneralInformation />
                            </DragAndDropCommonLayout>
                        </Suspense>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};
