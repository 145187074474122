import { OCRRequest, OCRRequestResult, OCRResult } from 'types/dataModels';
import { VestingResultDto } from 'types/dto/vesting';
import { LegalDescriptionResultDto } from 'types/dto/legalDescription';
import { SnackbarSeverity } from 'core/constants/common';
import { handleError } from 'core/helpers/errorHandler';
import { flattenDocuments } from 'core/helpers/flattenDocs';
import store from 'core/store/store';
import api from '../api';

/**
 * OCR RESULT
 */

/**
 * Returns the result of the OCR request
 * @param {string} OCRRequestId
 * @returns {OCRResult}
 */
export const getOCRResult = async (OCRRequestId: string) => {
    try {
        const response = await api.get<OCRResult>(
            `/api/OCRResult?ocrRequestId=${OCRRequestId}`
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Get the file
 * @param {string} OCRRequestId
 * @returns {string} file
 */
export const getOCRResultFilestream = async (OCRRequestId: string) => {
    try {
        const response = await api.get(
            `/api/OCRResult/filestream?OCRResultfileId=${OCRRequestId}`
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * OCR DOCUMENT REQUEST
 */

/**
 * Request to create new run of OCR for a given document
 * @param newOCRRequest Object holding order and document ID
 * @returns {OCRRequestResult}
 */
export const requestOCRForDocument = async (newOCRRequest: OCRRequest) => {
    try {
        const response = await api.post<OCRRequest, OCRRequestResult>('api/OCRRequest', {
            orderId: newOCRRequest.orderId,
            documentId: newOCRRequest.documentId
        });
        return response.data;
    } catch (err) {
        const docs = flattenDocuments(
            store.getState().examOrderKeyDocumentGroupData.examOrderDocumentGroup
        );
        const docName = docs.find(
            (doc) => doc.id === newOCRRequest.documentId
        )?.examinerDescriptionLink;
        await store.dispatch({
            type: 'snackbar/setSnackbarState',
            payload: {
                open: true,
                message: `OCR Request failed for ${docName}`,
                severity: SnackbarSeverity.Error
            }
        });
        handleError(err);
    }
};

/**
 * Get the status of the request being made for OCR
 * @param OCRRequestId ID of the OCR request
 * @returns {OCRRequestId}
 */
export const getStatusOfOCRRequest = async (OCRRequestId: string) => {
    try {
        const response = await api.get<OCRRequestResult>(`/api/OCRRequest?id=${OCRRequestId}`);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Get a request from order and document. This is used when you need to see
 * if we have OCR'd the document and have the results
 * @param orderId Id of the order
 * @param docId Id of the document
 */
export const getOCRRequestFromOrderAndDocument = async (orderId: string, docId: string) => {
    try {
        const response = await api.get<OCRRequestResult[]>(
            `/api/OCRRequest/${orderId}/${docId}`
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Make a request to cancel the OCR for a document
 * @param {string} ocrRequestId string for the cancel request
 * @returns {OCRRequestResult}
 */
export const cancelOCRRequest = async (ocrRequestId: string) => {
    try {
        const response = await api.put<null, OCRRequestResult>(
            `/api/OCRRequest/Cancel/${ocrRequestId}`
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * OCR DOCUMENT
 */

/**
 * Retrieve the XML/JS docs from the OCR results for a document
 * @param fileId
 * @param fileType
 * @returns {string}
 */
export const getOCRDataDocument = async (fileId: string, fileType: number) => {
    try {
        const response = await api.get<string>(`api/OCRDocument/file/${fileId}`, {
            headers: {
                'Content-Type': getFileType(fileType)
            }
        });
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Retrieve a PNG image of the OCR results for a document
 * @param fileId
 */
export const getOCRImageFile = async (fileId: string) => {
    try {
        const response = await api.getPngFile<ArrayBuffer>(`api/OCRDocument/file/${fileId}`);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Updating a legal instrument value
 * @param {string} orderId
 * @param {string } documentId
 * @param {string} updatedValue
 * @param {boolean} isTagged
 * @returns { LegalDescriptionValue[] } all legal records
 */
export const updateLegalInstrument = async (
    orderId: string,
    documentId: string,
    updatedValue: string,
    isTagged: boolean
) => {
    try {
        const response = await api.put<
            { value: string; isTagged: boolean },
            LegalDescriptionResultDto
        >(`api/OCR/${orderId}/document/${documentId}/legal`, {
            value: updatedValue,
            isTagged: isTagged
        });
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Updating a vesting instrument value
 * @param {string} orderId
 * @param {string } documentId
 * @param {string} updatedValue
 * @param {boolean} isTagged
 * @returns {VestingValue[]} all vesting records
 */
export const updateVestingInstrument = async (
    orderId: string,
    documentId: string,
    updatedValue: string,
    isTagged: boolean
) => {
    try {
        const response = await api.put<{ value: string; isTagged: boolean }, VestingResultDto>(
            `api/OCR/${orderId}/document/${documentId}/vesting`,
            {
                value: updatedValue,
                isTagged: isTagged
            }
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

const getFileType = (type: number) => {
    switch (type) {
        case 0:
            return 'application/xml';
        case 1:
            return 'application/octet-stream';
        case 2:
            return 'application/javascript';
        default:
            return '';
    }
};
