import { useState } from "react";
import colors from "core/constants/colors";
import { textStylesRoboto } from "styles/common";
import { Box, Typography, Link, Stack } from "@mui/material";
import { useAppSelector } from 'core/hooks/useAppSelector';
interface Notification {
    id: string;
    message: string;
    startDateTime: string;
    endDateTime: string;
}
const NotificationBanner = () => {
    const [showAll, setShowAll] = useState(false);
    const { body_strong_14_semibold } = textStylesRoboto().classes;
    const notifications = useAppSelector((state): Notification[] => state.notificationBannerData.notifications);

    if (notifications?.length === 0) return null;
    const formatTextWithLinks = (text: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex).map((part, index) => {
            if (urlRegex.test(part)) {
                try {
                    return (
                        <Link key={index} href={part} target="_blank" rel="noopener noreferrer" underline="always">
                            Click here
                        </Link>
                    );
                } catch {
                    return part;
                }
            }
            return part;
        });
    };
    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", p: 1 }}>
            <Stack
                direction="row"
                alignItems={notifications.length > 3 ? "flex-start" : "center"}
                spacing={2}
                sx={{
                    maxWidth: "80%",
                    textAlign: "center",
                    overflowY: notifications.length > 3 && showAll ? "auto" : "visible",
                    maxHeight: notifications.length > 3 && showAll ? 150 : "none",
                    padding: notifications.length > 3 ? "12px" : 0,
                    marginTop: '3.5px'
                }}
            >

                <Typography variant="body2" className={body_strong_14_semibold}
                    color={colors.additional.error_fail}
                    sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                    {notifications.slice(0, showAll ? notifications.length : 1).map((msg, i) => (
                        <div key={msg.id}>{i + 1}. {formatTextWithLinks(msg.message)}</div>
                    ))}
                </Typography>
                {notifications.length > 1 && (
                    <Typography
                        variant="body2"
                        sx={{ cursor: "pointer", color: colors.main.tertiaryDark, ml: "auto" }}
                        onClick={() => setShowAll(!showAll)}
                    >
                        {showAll ? "Hide" : <>Show all
                            {/* <sup style={{ position: "relative", top: "-15px" }}>
                                <span style={{ borderRadius: '20%', padding: '3.5px 5px', backgroundColor: '#483D91', color: '#FFFFFF', fontSize: 'small' }}>
                                    {notifications.length}
                                </span>
                            </sup> */}
                        </>}
                    </Typography>
                )}
            </Stack>

        </Box>
    );
};
export default NotificationBanner;