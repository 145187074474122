import { handleError } from 'core/helpers/errorHandler';
import {
    DocumentCategorySubCategoryDto,
    DocumentTypeNCodesResponse,
    DocumentCode,
    UpdateKeyDocumentInstrument,
    WarningsResponse,
    RecordedDateWithPara,
    AmountsWithPara,
    UpdateKeyDocumentInstrumentWithPara,
    StringFieldWithPara,
    StringFieldDto
} from 'types/dataModels';
import { AllowedPartyRolesMapping } from 'core/constants/common';
import api from '../api';

/**
 * Update party in document
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document in order,
 * @param {string} partyType - string - The id of the party in document,
 * @param {string} examValue - new display value
 * @returns {StringFieldDto}
 */
export const updateDocumentPartyApi = async (
    orderId: string,
    documentId: string,
    partyType: string,
    examValue: string
): Promise<StringFieldDto> => {
    try {
        const response = await api.put<string, StringFieldDto>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/partydto/${partyType}?examValue=${encodeURIComponent(
                examValue
            )}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Update party in document, this includes updates to
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document in order,
 * @param {string} partyType - string - The id of the party in document,
 * @param {string} examValue - new display value
 * @returns {PulseDocumentPartyFieldWithPara}
 */
export const updateDocumentPartyWithParagraphsApi = async (
    orderId: string,
    documentId: string,
    partyType: string,
    examValue: string
): Promise<StringFieldWithPara> => {
    try {
        const response = await api.put<string, StringFieldWithPara>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/partydto/${partyType}/withParagraphs?examValue=${encodeURIComponent(
                examValue
            )}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * This function updates the recorded date of a document in an exam order.
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document in order,
 * @param {string} examValue - string - The new recorded date
 * @returns {{value:string}}
 */
export const updateDocumentRecordedDateApi = async (
    orderId: string,
    documentId: string,
    examValue: string
): Promise<{ value: string; warnings: WarningsResponse[] }> => {
    try {
        const response = await api.put<null, { value: string; warnings: WarningsResponse[] }>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/recordedDate?examValue=${examValue}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * This function updates the recorded date of a document in an exam order.
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document in order,
 * @param {string} examValue - string - The new recorded date
 * @returns {{value:string}}
 */
export const updateDocumentRecordedDateWithParagraphsApi = async (
    orderId: string,
    documentId: string,
    examValue: string
): Promise<RecordedDateWithPara> => {
    try {
        const response = await api.put<null, RecordedDateWithPara>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/recordedDate/withParagraphs?examValue=${examValue}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * This function updates the instrumentId field of a document in an exam order
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The order id of the order you want to update
 * @param {string} documentId - The id of the document you want to update
 * @param {UpdateKeyDocumentInstrument} instrumentObject - new value of instrument
 * @returns {UpdateKeyDocumentInstrument}
 */
export const updateDocumentInstrumentApi = async (
    orderId: string,
    documentId: string,
    instrumentObject: UpdateKeyDocumentInstrument
): Promise<UpdateKeyDocumentInstrument> => {
    try {
        const response = await api.put<
            UpdateKeyDocumentInstrument,
            UpdateKeyDocumentInstrument
        >(
            `/api/examOrder/${orderId}/document/${documentId}/fields/instrumentId`,
            instrumentObject
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * This function updates the instrumentId field of a document in an exam order,
 * returns the updated paragraphs as well if there were any
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The order id of the order you want to update
 * @param {string} documentId - The id of the document you want to update
 * @param {UpdateKeyDocumentInstrument} instrumentObject - new value of instrument
 * @returns {UpdateKeyDocumentInstrumentWithPara}
 */
export const updateDocumentInstrumentWithParagraphsApi = async (
    orderId: string,
    documentId: string,
    instrumentObject: UpdateKeyDocumentInstrument
): Promise<UpdateKeyDocumentInstrumentWithPara> => {
    try {
        const response = await api.put<
            UpdateKeyDocumentInstrument,
            UpdateKeyDocumentInstrumentWithPara
        >(
            `/api/examOrder/${orderId}/document/${documentId}/fields/instrumentId/withParagraphs`,
            instrumentObject
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Add codes to document
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order
 * @param {string} documentId - string - The id of the document to add codes to
 * @param {number[]} codeIds - number[] - an array of code ids to add to the document
 * @returns {DocumentCode[]}
 */
export const addDocumentCodes = async (
    orderId: string,
    documentId: string,
    codeIds: string[]
): Promise<DocumentCode[]> => {
    try {
        const response = await api.post<string[], DocumentCode[]>(
            `/api/examOrder/${orderId}/document/${documentId}/addCodes`,
            codeIds
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Set codes to document (override)
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order
 * @param {string} documentId - The id of the document you want to set codes for
 * @param {number[]} codeIds - number[]
 * @returns {DocumentCode[]}
 */
export const setDocumentCodes = async (
    orderId: string,
    documentId: string,
    codeIds: string[]
): Promise<DocumentCode[]> => {
    try {
        const response = await api.post<string[], DocumentCode[]>(
            `/api/examOrder/${orderId}/document/${documentId}/setCodes`,
            codeIds
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It updates the amount field of a document in an order
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update
 * @param {number} amount - number - The new value of amount
 * @returns {{ value: number }}
 */
export const updateAmountField = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<{ value: number }> => {
    try {
        const response = await api.put<{ value: number }, { value: number }>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/amount`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It updates the amount field of a document in an order,
 * returns the pragraphs that were updated as a result of this field changing
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update
 * @param {number} amount - number - The new value of amount
 * @returns {{ value: number }}
 */
export const updateAmountFieldWithParagraphs = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<AmountsWithPara> => {
    try {
        const response = await api.put<{ value: number }, AmountsWithPara>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/amount/withParagraphs`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It updates the transfer tax amount field of a document in an order
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update.
 * @param {number} amount - number - The amount of the transfer tax amount
 * @returns {{ value: number }}
 */
export const updateTransferTaxAmountField = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<{ value: number }> => {
    try {
        const response = await api.put<{ value: number }, { value: number }>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/transferTaxAmount`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It updates the transfer tax amount field of a document in an order,
 * if the change to the amount involved a code, that updated code is returned in this response
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update.
 * @param {number} amount - number - The amount of the transfer tax amount
 * @returns {{ value: number }}
 */
export const updateTransferTaxAmountFieldWithParagraphs = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<AmountsWithPara> => {
    try {
        const response = await api.put<{ value: number }, AmountsWithPara>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/transferTaxAmount/withParagraphs`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It updates the amount field of an assessor document
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update.
 * @param {number} amount - number - The amount of the assessor amount
 * @returns {{ value: number }}
 */
export const updateAssessorAmountField = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<{ value: number }> => {
    try {
        const response = await api.put<{ value: number }, { value: number }>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/assessorAmount`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It sets categoy and subcategory of a document
 * @function
 * @category API
 * @param {DocumentCategorySubCategoryDto}
 * @returns {DocumentTypeNCodesResponse}
 */
export const setCategoryAndSubcategoryApi = async ({
    orderId,
    documentId,
    documentCategoryId,
    documentSubCategoryId,
    sourceDocumentTypeName
}: DocumentCategorySubCategoryDto): Promise<DocumentTypeNCodesResponse> => {
    try {
        const response = await api.put<
            DocumentCategorySubCategoryDto,
            DocumentTypeNCodesResponse
        >(`/api/examOrder/${orderId}/document/${documentId}/type`, {
            orderId,
            documentId,
            documentCategoryId,
            documentSubCategoryId,
            sourceDocumentTypeName
        });
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
